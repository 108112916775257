import React from "react"
import styled from "styled-components"
import { GatsbyImage } from "gatsby-plugin-image"
import { graphql } from "gatsby"
import BlockContent from "@sanity/block-content-to-react"
import Seo from "../components/Seo"
import Layout from "../components/Layout"
import Container from "../components/Container"
import BookImage from "../components/BookImage"
import PageHeader from "../components/PageHeader"
import PageTitle from "../components/PageTitle"
import PageContent from "../components/PageContent"

const serializers = {
  types: {
    code: props => (
      <pre data-language={props.node.language}>
        <code>{props.node.code}</code>
      </pre>
    ),
  },
}

const BooksPage = ({ data }) => {
  const book = data.book
  const bgImage = data.bgImage

  return (
    <Layout title="Books">
      <Seo title="Upcoming books by Joss Cannon" />
      <BackgroundImage
        image={bgImage.theImage.asset.gatsbyImageData}
        alt={bgImage.altText}
      />
      <PageHeader>
        <PageTitle title="Books" />
      </PageHeader>
      <PageContent>
        <Container>
          <FlexContainer>
            <BookImage
              image={book.coverImage.asset.gatsbyImageData}
              alt={book.altText}
              imgStyle={{ objectFit: "contain" }}
            />
            <ContentContainer>
              <Title>{book.title}</Title>
              <BlockContent
                blocks={book._rawDescription}
                serializers={serializers}
              />
            </ContentContainer>
          </FlexContainer>
          <Excerpt>{book.excerpt}</Excerpt>
        </Container>
      </PageContent>
    </Layout>
  )
}

export default BooksPage

const BackgroundImage = styled(GatsbyImage)`
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
`

const FlexContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  align-items: stretch;
`

const ContentContainer = styled.div`
  width: 100%;

  @media screen and (min-width: ${({ theme }) => theme.tablet}) {
    width: 55%;
    margin-left: 2rem;
    flex-shrink: 1;
    flex-grow: 1;
  }
`

const Title = styled.h3`
  font-size: 1.5rem;
  color: ${({ theme }) => theme.primaryDark};
  margin-bottom: 0.75rem;

  @media screen and (min-width: ${({ theme }) => theme.tablet}) {
    font-size: 1.75rem;
    margin-bottom: 2rem;
  }
`

const Excerpt = styled.blockquote`
  margin: 2.5rem 0;
  padding: 0 1rem;
  position: relative;

  :before {
    position: absolute;
    left: -0.3rem;
    top: -0.7rem;
    content: open-quote;
    color: ${({ theme }) => theme.accent};
    opacity: 0.3;
    font-size: 80px;
    font-weight: bold;
  }

  :after {
    position: absolute;
    right: -0.3rem;
    bottom: -1rem;
    content: close-quote;
    color: ${({ theme }) => theme.accent};
    opacity: 0.3;
    font-size: 80px;
    font-weight: bold;
  }

  @media screen and (min-width: ${({ theme }) => theme.mobile}) {
    margin-bottom: 2rem;
    padding: 0 2rem;
  }
`

export const query = graphql`
  query BooksPage {
    pageContent: sanityPage(slug: { current: { eq: "books" } }) {
      mainImage {
        asset {
          gatsbyImageData
        }
      }
      slug {
        current
      }
      title
      _rawBody
      altText
    }
    book: sanityBook(slug: { current: { eq: "duress" } }) {
      title
      _rawDescription
      excerpt
      altText
      coverImage {
        asset {
          gatsbyImageData
        }
      }
    }
    bgImage: sanitySingleImage(Id: { eq: "bg-books" }) {
      theImage {
        asset {
          gatsbyImageData
        }
      }
      altText
    }
  }
`
